body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F3F3;
}

.container {
    max-width: 1200px;
    display: block;
    margin: auto;
}
